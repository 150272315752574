@use "./abstracts" as *;

// .blog-header {
//     background-color: $color-primary;
//     color: $color-plain;

//     .blog-title {
//         @include flexCenter;
//         font-size: 3em;
//         height: 100%;
//     }
// }

.blog-page {
    min-height: calc(100vh - 50px);
    background-color: rgb(223, 200, 237);

    .blog {
        width: 80%;
        margin: 0 auto;
        padding: 40px;

        .article {
            padding: 40px;
            margin-top: 40px;
            background-color: white;

            &:first-child {
                margin-top: 0;
            }

            h1 {
                margin-bottom: 10px;
            }

            h2 {
                margin-bottom: 15px;
            }

            .date {
                margin-bottom: 20px;
            }

            #{&}-socials-container {
                width: 150px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
            }

            .article-body {
                .article-body-paragraph {
                    margin-top: 20px;

                    &.gif-insert {
                        @include flexCenter;
                        // width: 480px;
                        // height: 270px;
                        // margin-inline: auto;
                        // border: none;
                        // outline: none;
                        // box-shadow: none;

                        // width: 100%;
                        // height: 100%;
                        // max-height: 500px;
                        // padding-bottom: 75%;

                        iframe {
                            max-width: 100%;
                        }
                    }

                    & img {
                        max-height: 100%;
                        max-width: 100%;
                    }

                    a {
                        color: #547eb5;
                    }
                }
            }
        }
    }
}
