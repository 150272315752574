@use "./themes" as themes;
@use "./abstracts" as *;

.auth-page {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: map-deep-get(themes.$themes, "purple", "superdark");
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    .auth-page-title {
        margin-top: 15px;
        height: 500px;
        font-size: 90px;
        text-transform: uppercase;
        color: map-deep-get(themes.$themes, "purple", "lighttext");
        font-family: "Mynerve", cursive;
        transition: height 2s;
        @include flexCenter;

        &.auth-page-title-up {
            height: 150px;
        }
    }

    .auth-page-info {
        opacity: 0;
        transition: opacity 0.5s;
        margin-top: -10px;
        width: min(80%, 500px);
        color: map-deep-get(themes.$themes, "purple", "lighttext");

        &.auth-page-info-visible {
            opacity: 1;
        }
    }

    .auth-page-actions {
        margin-block: 30px;
        width: min(80%, 500px);
        opacity: 0;
        transition: opacity 0.5s;

        &.auth-page-actions-visible {
            opacity: 1;
        }

        .auth-page-go-to-blog {
            background-color: map-deep-get(themes.$themes, "purple", "superlight");
            color: map-deep-get(themes.$themes, "purple", "darktext");
            padding: 12px;
            border-radius: 8px;
            @include flexCenter;
            cursor: pointer;
        }

        .auth-container {
            background-color: map-deep-get(themes.$themes, "purple", "complement");
            padding: 12px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .auth-inputs-login {
                justify-content: center;
            }

            .auth-inputs-signup {
                justify-content: space-around;
            }

            .auth-inputs-container {
                display: flex;
                flex-direction: column;

                .auth-input-container {
                    margin-bottom: 20px;

                    .auth-input-help-text {
                        // display: flex;
                        // justify-content: space-between;
                        // align-items: end;
                        // .auth-input-label {

                        // }
                        .error-container {
                            .error {
                                color: map-deep-get(themes.$themes, "red", "normal");
                                font-style: italic;
                                font-size: small;
                            }
                        }
                    }

                    .auth-input {
                        border: none;
                        outline: none;
                        background-color: white;
                        height: 30px;
                        line-height: 30px;
                        padding: 6px 12px;
                        border-radius: 8px;
                        width: calc(100% - 24px);
                    }

                    .forgot-password-container {
                        display: flex;
                        justify-content: flex-end;

                        .forgot-password {
                            cursor: pointer;
                            font-style: italic;
                            font-size: small;

                            &:hover {
                                text-decoration: underline;
                                color: rgb(34, 97, 186);
                            }
                        }
                    }
                }
            }

            .auth-button {
                cursor: pointer;
                background-color: map-deep-get(themes.$themes, "purple", "normal");
                color: map-deep-get(themes.$themes, "purple", "lighttext");
                height: 30px;
                width: calc(100% - 24px);
                line-height: 30px;
                padding: 6px 12px;
                border-radius: 12px;
                text-align: center;
                margin-bottom: 10px;
            }

            .auth-alternate-link {
                text-align: center;
                cursor: pointer;
                width: 80%;
                margin-inline: 10%;

                &:hover {
                    text-decoration: underline;
                    color: rgb(34, 97, 186);
                }
            }
        }
    }
}
