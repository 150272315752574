@use "./abstracts" as *;

@each $key, $color in $colors {
    .circle-#{$key} {
        border: 4px solid $color;
        background-color: $color-plain;
        i {
            color: $color;
        }

        &:hover {
            background-color: $color;
            i {
                color: $color-plain;
            }
        }
    }
}

.circle-container {
    @include flexCenter;
    width: 140px;
    height: 140px;
    border-radius: 100%;
}

.circle {
    @include flexCenter(column);
    width: 140px;
    height: 140px;
    border-radius: 100%;
    color: $color-primary;
    font-family: sans-serif;
    font-weight: bolder;
}

.about {
    width: 120px;
    height: 120px;
    transition: width 1s, height 1s;
}

.socials-container {
    transition: bottom 1s;
}

.home-icon {
    color: $color-primary;
}

.header {
    height: 50px;
    @include flexCenter;
    letter-spacing: 0.2rem;
    font-family: Ink Free;
    font-weight: bold;
    color: rgb(198, 161, 220);
    line-height: 50px;

    .header-logo {
        position: absolute;
        left: 15px;
        @include flexCenter;
        font-family: Brush Script MT;
        font-size: large;
    }

    .header-title {
    }

    .header-link {
        position: absolute;
        right: 0;
        @include flexCenter;

        a {
            @include flexCenter;
            margin-right: 10px;
        }
    }
}
