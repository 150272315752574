@use "./abstracts" as *;

.home-page {
    height: calc(100vh - 50px);
    // background-color: rgb(223, 200, 237);
    background-color: black;
    .home-menu-items {
        display: flex;
        width: 100%;
        height: calc(100vh - 50px);
        justify-content: space-evenly;
        align-items: center;
        .home-menu-item {
            height: 300px;
            width: 300px;
            @include flexCenter;
        }
    }
}
