@use "sass:map";
@use "sass:math";

$black-theme: (
    superdark: #000000,
    superlight: #ffffff,
    normal: #708090,
    complement: #ffffff,
    complementtext: #000000,
    darktext: #000000,
    lighttext: #ffffff,
    contrast: #ffffff,
    contrasttext: #000000,
);

$white-theme: (
    superdark: #ffffff,
    superlight: #000000,
    normal: #dcdcdc,
    complement: #000000,
    complementtext: #ffffff,
    darktext: #ffffff,
    lighttext: #000000,
    contrast: #000000,
    contrasttext: #ffffff,
);

$blue-theme: (
    superdark: #191970,
    superlight: #e0ffff,
    normal: #00bfff,
    complement: #ff3f00,
    complementtext: #ffffff,
    darktext: #000000,
    lighttext: #ffffff,
    contrast: #ffd700,
    contrasttext: #000000,
);

$red-theme: (
    superdark: #a60c0c,
    superlight: #f4a0a0,
    normal: #cb2c2c,
    complement: #2ccbcb,
    // complement: #14dcb4,
    complementtext: #ffffff,
    darktext: #000000,
    lighttext: #ffffff,
    contrast: #ffed66,
    contrasttext: #000000,
);

$green-theme: (
    superdark: #006400,
    superlight: #98fb98,
    normal: #3cb371,
    complement: #cd32cd,
    complementtext: #ffffff,
    darktext: #000000,
    lighttext: #ffffff,
    contrast: #640b64,
    contrasttext: #ffffff,
);

$yellow-theme: (
    superdark: #ffd700,
    superlight: #fffacd,
    normal: #ffff00,
    // complement: #0000ff, // real complement
    complement: #3d3dce,
    complementtext: #ffffff,
    darktext: #000000,
    lighttext: #000000,
    contrast: #941b1b,
    contrasttext: #ffffff,
);

$purple-theme: (
    superdark: #4b0082,
    superlight: #d8bfd8,
    normal: #9370db,
    // complement: #b8db70, // real complement
    complement: #a5d642,
    complementtext: #ffffff,
    darktext: #000000,
    lighttext: #ffffff,
    contrast: #f8e403,
    contrasttext: #000000,
);

$pink-theme: (
    superdark: #c71585,
    superlight: #ffc0cb,
    normal: #ff69b4,
    // complement: #69ffb4, // real complement
    complement: #a7e021,
    complementtext: #ffffff,
    darktext: #000000,
    lighttext: #ffffff,
    contrast: skyblue,
    contrasttext: #ffffff,
);

$orange-theme: (
    superdark: #d2691e,
    superlight: #ffa07a,
    normal: #ff8c00,
    // complement: #0072ff, // real complement
    complement: #1cd0e4,
    complementtext: #ffffff,
    darktext: #000000,
    lighttext: #ffffff,
    contrast: #640b64,
    contrasttext: #ffffff,
);

$themes: (
    "black": $black-theme,
    "white": $white-theme,
    "blue": $blue-theme,
    "red": $red-theme,
    "green": $green-theme,
    "yellow": $yellow-theme,
    "purple": $purple-theme,
    "pink": $pink-theme,
    "orange": $orange-theme,
);

@function json-stringify($map) {
    $result: "{";

    @each $key, $value in $map {
        $result: $result + '"#{$key}":';

        @if type-of($value) == "map" {
            $result: $result + json-stringify($value);
        } @else {
            $result: $result + '"#{$value}"';
        }

        @if index($map, ($key $value)) != length($map) {
            $result: $result + ",";
        }
    }

    $result: $result + "}";

    @return $result;
}

:export {
    blackTheme: json-stringify($black-theme);
    whiteTheme: json-stringify($white-theme);
    blueTheme: json-stringify($blue-theme);
    redTheme: json-stringify($red-theme);
    greenTheme: json-stringify($green-theme);
    yellowTheme: json-stringify($yellow-theme);
    purpleTheme: json-stringify($purple-theme);
    pinkTheme: json-stringify($pink-theme);
    orangeTheme: json-stringify($orange-theme);
}
