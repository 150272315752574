@use "sass:math";

@use "./themes" as *;

$layout-values: flex-start, flex-end, center, space-between, space-around;

@each $val in $layout-values {
    .justify-#{$val} {
        justify-content: $val;
    }
}

@mixin flexCenter($direction: row) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

$color-primary: #0e021a;
$color-secondary: #e4d01c;
$color-plain: whitesmoke;
$color-red: #ff4141;
$color-blue: #87d7ff;
$color-green: #7ef681;
$color-purple: rgb(90, 22, 138);

$colors: (
    "primary": $color-primary,
    "secondary": $color-secondary,
    "plain": $color-plain,
    "red": $color-red,
    "blue": $color-blue,
    "green": $color-green,
    "default": whitesmoke,
    "purple": $color-purple,
);

// background-color
@each $key, $theme in $themes {
    @each $color-name, $color in $theme {
        .bg-#{$key}-#{$color-name} {
            background-color: $color;
        }
    }
}

// borders
@each $key, $theme in $themes {
    @each $color-name, $color in $theme {
        @for $i from 1 through 12 {
            .b-#{$key}-#{$color-name}-#{$i} {
                border: #{$i}px solid $color;
            }
            .b-t-#{$key}-#{$color-name}-#{$i} {
                border-top: #{$i}px solid $color;
            }
            .b-b-#{$key}-#{$color-name}-#{$i} {
                border-bottom: #{$i}px solid $color;
            }
            .b-l-#{$key}-#{$color-name}-#{$i} {
                border-left: #{$i}px solid $color;
            }
            .b-r-#{$key}-#{$color-name}-#{$i} {
                border-right: #{$i}px solid $color;
            }
        }
    }
}

// color
@each $key, $theme in $themes {
    @each $color-name, $color in $theme {
        .c-#{$key}-#{$color-name} {
            color: $color;
        }
    }
}

// background opacity
@each $key, $theme in $themes {
    @each $color-name, $color in $theme {
        @for $i from 0 through 10 {
            .op-#{$key}-#{$color-name}-#{$i} {
                background-color: rgba($color, math.div($i, 10));
            }
        }
    }
}

@keyframes moveUpAndDown {
    0%,
    75%,
    100% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-10px);
    }
}
