.not-found-page {
    position: fixed;
    // background-color: rgb(50, 101, 145);
    height: 20vh;
    width: 25vw;
    min-height: 160px;
    min-width: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .not-found-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        .not-found-text {
            // color: white;
            font-weight: bold;
            padding-bottom: 20px;
        }
        .not-found-button {
            cursor: pointer;
            background-color: rgb(43, 104, 154);
            line-height: 20px;
            padding: 6px 12px;
            border-radius: 12px;
            text-align: center;
            color: white;
        }
    }
}
