@use "./themes" as themes;
@use "./abstracts" as *;

.projects-page {
    height: calc(100vh - 50px);
    // background-color: rgb(223, 200, 237);
    background-color: black;
    .projects {
        display: flex;
        width: 100%;
        height: calc(100vh - 50px);
        justify-content: space-evenly;
        align-items: center;
        .project-card {
            height: 300px;
            width: 300px;
            @include flexCenter;
        }
    }
}

.plot-project-page {
    min-height: calc(100vh - 50px);
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;

    .inputs-container {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        width: 526px;

        .scene-input {
            margin: 0;
            padding: 6px;
            height: 30px;
            line-height: 30px;
            width: 400px;
            outline: none;
            border: none;
        }

        .rating-input {
            margin: 0;
            padding: 6px;
            height: 30px;
            line-height: 30px;
            width: 30px;
            text-align: center;
            outline: none;
            border: none;
        }

        .add-scene {
            height: 30px;
            line-height: 30px;
            width: 30px;
            @include flexCenter;
            background-color: #83b3f1;
            padding: 6px;
            cursor: pointer;
        }
    }

    .scenes-container {
        color: white;
        margin-block: 20px;
        height: calc(100vh - 50px - 42px - 20px - 42px - 20px - 40px);
        overflow-y: auto;
        background-color: black;

        .scene-container {
            display: flex;
            justify-content: center;
            width: 526px;

            .scene {
                width: 400px;
                padding: 6px;
                height: 30px;
                line-height: 30px;
            }

            .rating {
                height: 30px;
                line-height: 30px;
                width: 30px;
                padding: 6px;
                margin-left: 15px;
                @include flexCenter;
            }
        }
    }

    .buttons-container {
        position: absolute;
        bottom: 20px;
        width: 200px;
        display: flex;
        justify-content: space-between;

        .shuffle {
            height: 30px;
            line-height: 30px;
            padding: 6px;
            background-color: rgb(114, 181, 13);
            color: white;
            text-align: center;
            cursor: pointer;
        }

        .download {
            height: 30px;
            line-height: 30px;
            padding: 6px;
            background-color: red;
            color: white;
            text-align: center;
        }
    }
}

.colors-project-page {
    min-height: calc(100vh - 50px);
    background-color: black;
    display: flex;
    align-items: center;

    .color-selectors-container {
        @include flexCenter;
        flex-wrap: wrap;
        width: 50%;

        .color-selector {
            height: 50px;
            width: 100px;
            border-radius: 8px;
            @include flexCenter;
            margin: 20px;

            .color-selector-add {
                height: 50px;
                width: 50px;
                border-radius: 8px;
                cursor: pointer;
                @include flexCenter;
            }
        }

        .color-wheel-container {
            width: 200px;
            @include flexCenter;
            margin: 20px;
        }
    }

    .colors-result-section {
        min-height: calc(100vh - 50px);
        width: 50%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .colors-result-options-container {
            @include flexCenter;
            margin: 20px;

            .colors-result-option {
                background-color: blue;
                color: white;
                height: 50px;
                width: 100px;
                @include flexCenter;
                margin-inline: 10px;
                cursor: pointer;
            }
        }

        .colors-result-image {
            height: 300px;
            width: 300px;
            margin-inline: auto;
        }

        .colors-result-download-button-container {
            @include flexCenter;
            margin-top: 20px;

            .colors-result-download-button {
                background-color: red;
                color: white;
                height: 50px;
                width: 100px;
                @include flexCenter;
                cursor: pointer;
            }
        }
    }
}

.stopwatch-project-page {
    min-height: calc(100vh - 50px);
    background-color: rgb(223, 200, 237);

    .stopwatches {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        .stopwatch-box {
            background-color: #547eb5;
            border-radius: 8px;
            padding: 20px;
            margin: 20px;
            height: 300px;
            width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .stopwatch-label {
                text-align: center;
                color: white;

                input {
                    background-color: inherit;
                    border: none;
                    outline: none;
                    text-align: center;
                }
            }

            .stopwatch-container {
                width: 200px;
                height: 200px;
                @include flexCenter;

                .stopwatch-times {
                    position: absolute;
                    width: 200px;
                    height: 200px;
                    color: white;
                    @include flexCenter(column);

                    .stopwatch-current {
                        @include flexCenter;
                        font-size: x-large;
                        margin-top: 10px;
                    }

                    .stopwatch-goal {
                        text-align: center;
                        display: table;
                        color: white;

                        input {
                            display: table-cell;
                            background-color: inherit;
                            border: none;
                            outline: none;
                            text-align: center;
                            max-width: 25px;
                        }
                    }
                }

                .stopwatch {
                    // padding: 20px;
                    // width: 50%;
                    // height: 300px;
                    // margin: 40px;
                    // border-radius: 50%;
                    // background-color: orange;
                }
            }
            .stopwatch-buttons {
                display: flex;
                justify-content: space-between;
                width: 180px;
                .stopwatch-button {
                    height: 40px;
                    width: 50px;
                    border-radius: 8px;
                    background-color: lavender;
                    color: #33006f;
                    cursor: pointer;
                    @include flexCenter;
                }
            }
        }
    }
}
